// src/App.js
import React, { Suspense, lazy } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';
import CheckInGenerator from './components/CheckInGenerator';
import CookieConsent from './components/CookieConsent';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import AuthCallback from './components/AuthCallback';
import { usePageTracking } from './hooks/usePageTracking';

// Lazy load non-critical components
const About = lazy(() => import('./components/About'));
const Contact = lazy(() => import('./components/Contact'));
const Support = lazy(() => import('./components/Support'));
const Profile = lazy(() => import('./components/Profile'));
const PaymentSuccess = lazy(() => import('./components/PaymentSuccess'));
const Pricing = lazy(() => import('./components/Pricing'));
const PrivacyPolicy = lazy(() => import('./components/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./components/TermsOfService'));
const HowTo = lazy(() => import('./components/HowTo'));
const VerifyEmail = lazy(() => import('./pages/VerifyEmail'));
const SavedQuestions = lazy(() => import('./components/SavedQuestions'));

// Loading fallback component
const LoadingFallback = () => (
  <div className="min-h-[60vh] flex items-center justify-center">
    <div className="text-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto mb-4"></div>
      <h2 className="text-xl text-gray-600">Loading...</h2>
    </div>
  </div>
);

function SEO({ 
  title = "Check-In Questions - AI-Powered Team Meeting Questions Generator",
  description = "Generate engaging check-in questions for team meetings. AI-powered platform to foster psychological safety and team connection. Try for free today!",
  type = "website"
}) {
  const location = useLocation();
  const baseUrl = 'https://checkinquestions.com';
  const canonicalUrl = `${baseUrl}${location.pathname}`;
  const imageUrl = `${baseUrl}/og-image.png`;

  return (
    <Helmet>
      {/* Basic metadata */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={canonicalUrl} />

      {/* OpenGraph metadata */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:site_name" content="Check-In Questions" />
      <meta property="og:locale" content="en_US" />

      {/* Twitter metadata */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:url" content={canonicalUrl} />

      {/* Additional SEO metadata */}
      <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      <link rel="alternate" href={canonicalUrl} hrefLang="en" />
      <link rel="alternate" href={canonicalUrl} hrefLang="x-default" />
    </Helmet>
  );
}

export default function App() {
  usePageTracking();
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-500 via-blue-400 via-70% to-blue-200 flex flex-col">
      <SEO />
      <div className="flex-grow">
        <Navigation />
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            {/* Public routes */}
            <Route path="/auth/v1/callback" element={<AuthCallback />} />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/support" element={<Support />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/how-to" element={<HowTo />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/payment/success" element={<PaymentSuccess />} />

            {/* Root route - accessible to both authenticated and unauthenticated users */}
            <Route path="/" element={<CheckInGenerator />} />

            {/* Protected routes */}
            <Route
              path="/profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/saved-questions"
              element={
                <ProtectedRoute>
                  <SavedQuestions />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Suspense>
        <CookieConsent />
      </div>
      <Footer />
    </div>
  );
}