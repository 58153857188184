// src/components/signin.js

import React from 'react';
import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { trackSignIn, trackError } from '../lib/analytics';
import GoogleSignInButton from './GoogleSignInButton';
import { Helmet } from 'react-helmet-async';
import { usePageTracking } from '../hooks/usePageTracking';

export default function SignIn() {
    const { signIn, resetPassword } = useAuth();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    usePageTracking('Sign In');

    const handleSignIn = async (e) => {
        e.preventDefault();
        setError("");
        setSuccess("");
        setLoading(true);

        try {
            const { error: signInError } = await signIn(email, password);
            if (signInError) throw signInError;

            trackSignIn('email');
            
            navigate('/', { replace: true });
        } catch (err) {
            console.error("Error:", err);
            trackError('authentication', err.message);
            setError(err.message || "Failed to sign in");
        } finally {
            setLoading(false);
        }
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (!email) {
            setError("Please enter your email address");
            return;
        }
        
        setError("");
        setSuccess("");
        setLoading(true);

        try {
            await resetPassword(email);
            setSuccess("Check your email for password reset instructions");
            trackSignIn('reset-password-request');
        } catch (err) {
            console.error("Error:", err);
            trackError('reset-password', err.message);
            setError(err.message || "Failed to send reset password email");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Sign In - Check-In Questions</title>
                <meta name="description" content="Sign in to your Check-In Questions account to access your saved questions, premium features, and continue fostering engaging team discussions." />
                <meta property="og:title" content="Sign In - Check-In Questions" />
                <meta property="og:description" content="Sign in to your Check-In Questions account to access your saved questions, premium features, and continue fostering engaging team discussions." />
            </Helmet>
            <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
                <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">

                  <h1 className="text-3xl font-bold mb-6">Sign In</h1>

                    <form onSubmit={handleSignIn} className="space-y-4">
                        <div>
                            <label htmlFor="email" className="sr-only">Email</label>
                            <input
                                id="email"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                required
                                className="w-full p-2 border rounded focus:ring-2 focus:ring-black focus:border-black"
                            />
                        </div>

                        <div>
                            <label htmlFor="password" className="sr-only">Password</label>
                            <input
                                id="password"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="Password"
                                required
                                className="w-full p-2 border rounded focus:ring-2 focus:ring-black focus:border-black"
                            />
                        </div>

                        <div className="flex justify-end">
                            <button
                                type="button"
                                onClick={handleResetPassword}
                                className="text-sm text-blue-500 hover:text-blue-700"
                            >
                                Forgot password?
                            </button>
                        </div>

                        <button
                            type="submit"
                            disabled={loading}
                            className="w-full p-2 bg-black text-white rounded hover:bg-gray-800 disabled:opacity-50"
                        >
                            {loading ? "Signing In..." : "Sign In"}
                        </button>

                        <div className="relative my-6">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300"></div>
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">Or continue with</span>
                            </div>
                        </div>

                        <div className="w-full flex justify-center items-center">
                            <GoogleSignInButton onError={setError} />
                        </div>
                    </form>

                    {error && (
                        <p className="mt-2 text-sm text-red-500">{error}</p>
                    )}

                    {success && (
                        <p className="mt-2 text-sm text-green-500">{success}</p>
                    )}

                    <p className="mt-4 text-center text-sm text-blue-500">
                        <Link to="/sign-up">
                            Don't have an account? Sign up
                        </Link>
                    </p>
                </div>
            </div>
        </>
    );
}