// src/contexts/AuthContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the initial session
    const getSession = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Error getting session:', error);
      }
      setSession(session);
      setUser(session?.user ?? null);
      setLoading(false);
    };

    getSession();

    // Subscribe to auth state changes
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setUser(session?.user ?? null);
    });

    // Cleanup subscription
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        setUser(session?.user ?? null);
      } else if (event === 'SIGNED_OUT') {
        setUser(null);
      } else if (event === 'USER_UPDATED') {
        setUser(session?.user ?? null);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const signUp = async ({ email, password, fullName }) => {
    console.log('🚀 Starting signup process...', { email, hasPassword: !!password, fullName });
    
    try {
      // Input validation
      if (!email || !password || !fullName) {
        console.error('❌ Missing required fields:', {
          hasEmail: !!email,
          hasPassword: !!password,
          hasFullName: !!fullName
        });
        throw new Error('Please fill in all fields');
      }

      console.log('📝 Creating auth user...');
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            full_name: fullName
          }
        }
      });

      if (signUpError) {
        console.error('❌ Auth signup error:', {
          code: signUpError.code,
          message: signUpError.message,
          details: signUpError.details,
          hint: signUpError.hint
        });
        throw signUpError;
      }

      if (!authData?.user?.id) {
        console.error('❌ No user ID returned from signup');
        throw new Error('No user ID returned from signup');
      }

      console.log('✅ Auth user created successfully:', {
        userId: authData.user.id,
        email: authData.user.email,
        metadata: authData.user.user_metadata
      });

      // Profile creation will be handled by the database trigger
      console.log('👤 Profile creation will be handled by database trigger');

      return {
        data: authData,
        error: null
      };

    } catch (error) {
      console.error('❌ Signup process error:', {
        name: error.name,
        message: error.message,
        code: error.code,
        details: error?.details,
        hint: error?.hint
      });
      
      let errorMessage = 'Unable to create account. Please try again later.';
      
      if (error.message?.includes('already registered')) {
        errorMessage = 'This email is already registered. Please sign in instead.';
      } else if (error.message?.includes('valid email')) {
        errorMessage = 'Please enter a valid email address.';
      } else if (error.message?.includes('password')) {
        errorMessage = 'Password must be at least 6 characters long.';
      }

      return {
        data: null,
        error: new Error(errorMessage)
      };
    }
  };

  const signIn = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      console.error('Error signing in:', error);
      throw error;
    }
    return data;
  };

  const signInWithGoogle = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}/auth/callback`,
      },
    });
    
    if (error) {
      console.error('Error signing in with Google:', error);
      throw error;
    }
    
    return data;
  };

  const signOutUser = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error signing out:', error);
      throw error;
    }
    navigate('/sign-in'); // Redirect after sign out
  };

  const resetPassword = async (email) => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/reset-password`
      });
      
      if (error) {
        console.error('Error details:', error);
        if (error.message.includes('SMTP')) {
          throw new Error('Email service is not configured. Please contact support.');
        }
        throw error;
      }
    } catch (err) {
      console.error('Full error:', err);
      throw err;
    }
  };

  const verifyEmail = async (token) => {
    try {
      const { error } = await supabase.auth.verifyOtp({
        token_hash: token,
        type: 'email',
      });

      if (error) {
        console.error('Error verifying email:', error);
        throw error;
      }
    } catch (err) {
      console.error('Full error:', err);
      throw err;
    }
  };

  const resendVerificationEmail = async (email) => {
    if (!email) {
      throw new Error('Email is required');
    }

    const { error } = await supabase.auth.resend({
      type: 'signup',
      email: email,
    });

    if (error) throw error;
    return { success: true };
  };

  const value = {
    signUp,
    signIn,
    signInWithGoogle,
    signOut: signOutUser,
    resetPassword,
    verifyEmail,
    resendVerificationEmail,
    user,
    session,
    supabase,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);