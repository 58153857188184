import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { trackSignUp, trackError } from '../lib/analytics';
import GoogleSignInButton from './GoogleSignInButton';
import { usePageTracking } from '../hooks/usePageTracking';

export default function SignUp() {
  const navigate = useNavigate();
  const { signUp } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  usePageTracking('Sign Up');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (!email || !password || !fullName) {
        setError('Please fill in all fields');
        setLoading(false);
        return;
      }

      // Store full name for later use during verification
      localStorage.setItem('signup_full_name', fullName);

      const result = await signUp({
        email,
        password,
        fullName
      });
      
      if (result.error) {
        setError(result.error.message);
        return;
      }

      trackSignUp('email');
      navigate('/verify-email?email=' + encodeURIComponent(email));
      
    } catch (err) {
      console.error('Sign up error:', err);
      trackError('authentication', err.message);
      setError(err.message || 'Failed to create account');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign Up - Check-In Questions</title>
        <meta name="description" content="Create your Check-In Questions account to start generating AI-powered team meeting questions. Get started with our free plan or explore premium features." />
        <meta property="og:title" content="Sign Up - Check-In Questions" />
        <meta property="og:description" content="Create your Check-In Questions account to start generating AI-powered team meeting questions. Get started with our free plan or explore premium features." />
      </Helmet>
      <div className="max-w-4xl mx-auto px-1 py-1 md:py-4">
        <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4">
          <h1 className="text-3xl font-bold mb-6">Create Account</h1>

          {error && (
            <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
              {error}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="fullName" className="block text-sm font-medium text-gray-700 mb-1">Full Name</label>
              <input
                id="fullName"
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Enter your full name"
                required
                className="w-full p-2 border rounded focus:ring-2 focus:ring-black focus:border-black"
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                className="w-full p-2 border rounded focus:ring-2 focus:ring-black focus:border-black"
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">Password</label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Create a password"
                required
                minLength={6}
                className="w-full p-2 border rounded focus:ring-2 focus:ring-black focus:border-black"
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full p-2 bg-black text-white rounded hover:bg-gray-800 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? "Creating Account..." : "Sign Up"}
            </button>

            <div className="relative my-6">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300"></div>
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Or continue with</span>
              </div>
            </div>

            <div className="w-full flex justify-center items-center">
              <GoogleSignInButton onError={setError} />
            </div>
          </form>

          <p className="mt-4 text-center text-sm text-gray-600">
            Already have an account?{' '}
            <Link to="/sign-in" className="text-blue-600 hover:text-blue-800">
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
