// src/components/checkingenerator.js

import React, { useRef, memo, useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useSubscriptionStatus } from '../contexts/SubscriptionContext';
import QuestionActions from './QuestionActions';
import useQuestionGenerator from '../hooks/useQuestionGenerator';
import { CaretDown, CaretUp, Lock, ArrowsOutSimple, ArrowsInSimple, Copy } from "@phosphor-icons/react";
import { Helmet } from 'react-helmet-async';
import { createCheckoutSession } from '../lib/stripe';
import { SUBSCRIPTION_PRICES } from '../lib/stripe-config';
import { trackSubscriptionAttempt } from '../lib/analytics';

// Memoized QuestionActions component
const MemoizedQuestionActions = memo(QuestionActions);

// Memoized control option
const ControlOption = memo(({ 
  label, 
  emoji, 
  isSelected, 
  onChange,
  ariaLabel
}) => (
  <label className="flex items-center justify-between text-xl">
    <span>{emoji} {label}</span>
    <div className="relative inline-block w-12 h-6 transition duration-200 ease-in-out">
      <input
        type="checkbox"
        checked={isSelected}
        onChange={onChange}
        className="absolute w-6 h-6 transition duration-200 ease-in-out transform bg-white border rounded-full appearance-none cursor-pointer checked:translate-x-full checked:bg-black"
      />
      <div
        className={`w-full h-full rounded-full ${
          isSelected ? 'bg-green-500' : 'bg-gray-200'
        }`}
      />
    </div>
  </label>
));

// Helper function to clean quotes from text
const cleanQuotes = (text) => {
  return text.replace(/["""'']+/g, '');
};

// Helper function to get question type pill
const getQuestionTypePill = (type) => {
  switch (type) {
    case 'energizer':
      return (
        <span className="inline-block bg-green-100 text-green-800 text-xs font-medium px-3 py-0.5 rounded-full">
          Energizer
        </span>
      );
    case 'check-in':
      return (
        <span className="inline-block bg-blue-100 text-blue-800 text-xs font-medium px-3 py-0.5 rounded-full">
          Check-In Question
        </span>
      );
    case 'check-out':
      return (
        <span className="inline-block bg-purple-100 text-purple-800 text-xs font-medium px-3 py-0.5 rounded-full">
          Check-Out Question
        </span>
      );
    default:
      return null;
  }
};

// Popular Question Item Component
const PopularQuestionItem = memo(({ 
  question, 
  toggleEnergizer, 
  expandedEnergizers, 
  onVote, 
  getQuestionTypePill 
}) => {
  const questionRef = useRef(null);
  const [isQuestionFullscreen, setIsQuestionFullscreen] = useState(false);
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);

  // Handle fullscreen changes for this specific question
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsQuestionFullscreen(document.fullscreenElement === questionRef.current);
    };
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  // Toggle fullscreen for this specific question
  const toggleQuestionFullscreen = async () => {
    try {
      if (!isQuestionFullscreen) {
        await questionRef.current.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }
    } catch (error) {
      console.error('Error toggling fullscreen:', error);
    }
  };

  // Copy to clipboard function
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowCopyConfirmation(true);
      setTimeout(() => setShowCopyConfirmation(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <div
      ref={questionRef}
      className={`bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 border border-gray-200 p-6 relative ${
        isQuestionFullscreen 
          ? 'fixed inset-0 z-50 flex items-center justify-center m-0 rounded-none bg-white' 
          : ''
      }`}
    >
      {/* Action buttons container */}
      <div className="absolute top-4 right-4 flex items-center gap-2">
        {/* Copy button */}
        <button
          onClick={() => {
            if (question.type === 'energizer') {
              try {
                // Try both question.content and question.question fields
                const energizerContent = question.content || question.question;
                const energizer = typeof energizerContent === 'string' 
                  ? JSON.parse(energizerContent) 
                  : energizerContent;
                
                copyToClipboard(
                  `${energizer.emoji} ${energizer.title}\n\n${energizer.description}\n\n⏱️ Time: ${energizer.timeFrame}\n👥 Group Size: ${energizer.groupSize}\n🎯 Purpose: ${energizer.purpose}\n\nSteps:\n${energizer.steps.map((step, index) => `${index + 1}. ${step}`).join('\n')}\n\n${energizer.facilitatorNotes ? `Facilitator Notes:\n${energizer.facilitatorNotes.map(note => `• ${note}`).join('\n')}` : ''}`
                );
              } catch (error) {
                console.error('Error parsing energizer content:', error);
              }
            } else {
              copyToClipboard(question.question);
            }
          }}
          className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-full transition-colors relative"
          aria-label="Copy to clipboard"
        >
          <Copy size={18} weight="bold" />
          {/* Copy confirmation tooltip */}
          {showCopyConfirmation && (
            <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 bg-black text-white text-xs px-2 py-1 rounded whitespace-nowrap">
              Copied!
            </span>
          )}
        </button>

        {/* Fullscreen button */}
        <button
          onClick={toggleQuestionFullscreen}
          className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-full transition-colors"
          aria-label={isQuestionFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
        >
          {isQuestionFullscreen ? (
            <ArrowsInSimple size={18} weight="bold" />
          ) : (
            <ArrowsOutSimple size={18} weight="bold" />
          )}
        </button>
      </div>

      <div className={`${isQuestionFullscreen ? 'max-w-4xl mx-auto' : ''}`}>
        <div className="mb-3">
          {getQuestionTypePill(question.type)}
        </div>

        {question.type === 'energizer' ? (
          <div>
            {(() => {
              try {
                // Try both question.content and question.question fields
                const energizerContent = question.content || question.question;
                const energizer = typeof energizerContent === 'string' 
                  ? JSON.parse(energizerContent) 
                  : energizerContent;

                return (
                  <div className="space-y-3">
                    <div className="space-y-3">
                      <h2 className={`font-bold leading-tight ${isQuestionFullscreen ? 'text-4xl' : 'text-2xl'}`}>
                        {energizer.emoji} {energizer.title}
                      </h2>
                      <p className="text-gray-700 leading-relaxed">{energizer.description}</p>
                    </div>

                    {/* Always show details in fullscreen, otherwise use toggle */}
                    {!isQuestionFullscreen && (
                      <button
                        onClick={() => toggleEnergizer(question.id)}
                        className="flex items-center gap-2 text-blue-600 mt-3 hover:text-blue-700 font-medium"
                      >
                        <span>
                          {expandedEnergizers.has(question.id) ? 'Show less' : 'Read more'}
                        </span>
                        {expandedEnergizers.has(question.id) ? <CaretUp size={16} /> : <CaretDown size={16} />}
                      </button>
                    )}

                    {(isQuestionFullscreen || expandedEnergizers.has(question.id)) && (
                      <div className="pt-4 space-y-5">
                        <div className="bg-gray-50 p-4 rounded-lg border border-gray-100">
                          <p className="text-gray-700 mb-2"><strong>⏱️ Time:</strong> {energizer.timeFrame}</p>
                          <p className="text-gray-700 mb-2"><strong>👥 Group Size:</strong> {energizer.groupSize}</p>
                          <p className="text-gray-700"><strong>🎯 Purpose:</strong> {energizer.purpose}</p>
                        </div>
                        {energizer.steps && (
                          <div>
                            <h3 className="font-bold text-lg mb-3">Steps</h3>
                            <ol className="list-decimal ml-6 space-y-3">
                              {energizer.steps.map((step, index) => (
                                <li key={index} className="leading-relaxed">{step}</li>
                              ))}
                            </ol>
                          </div>
                        )}
                        {energizer.facilitatorNotes && (
                          <div>
                            <h3 className="font-bold text-lg mb-3">Facilitator Notes</h3>
                            <ul className="list-disc ml-6 space-y-3">
                              {energizer.facilitatorNotes.map((note, index) => (
                                <li key={index} className="leading-relaxed">{note}</li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              } catch (error) {
                console.error('Error parsing energizer content:', error);
                return <p className="text-red-500">Error displaying energizer content</p>;
              }
            })()}
          </div>
        ) : (
          <div className="text-center">
            <p className={`font-bold ${isQuestionFullscreen ? 'text-5xl leading-tight' : 'text-3xl leading-snug'} tracking-tight text-gray-800`}>
              &ldquo;{cleanQuotes(question.content || question)}&rdquo;
            </p>
          </div>
        )}
        {!isQuestionFullscreen && (
          <>
            <MemoizedQuestionActions
              question={question}
              onVote={onVote}
            />
            <div className="text-center mt-4 text-sm text-gray-500">
              {question.voteCount || 0} helpful {(question.voteCount === 1) ? 'vote' : 'votes'}
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default function CheckInGenerator() {
  const { user } = useAuth();
  const { subscriptionStatus } = useSubscriptionStatus();
  const questionBoxRef = useRef(null);
  const [expandedEnergizers, setExpandedEnergizers] = useState(new Set());
  const [error] = useState(null);
  const [isAnnual, setIsAnnual] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showCopyConfirmation, setShowCopyConfirmation] = useState(false);
  // Add a ref to track if we've attempted auto-generation
  const hasAttemptedAutoGeneration = useRef(false);
  // Add state for initial loading
  const [initialLoading, setInitialLoading] = useState(false);

  const {
    generationsLeft,
    selectedType,
    selectedMood,
    selectedComplexity,
    isLoading,
    generatedQuestion,
    previousQuestions,
    handleTypeChange,
    handleMoodChange,
    handleComplexityChange,
    generateQuestion,
    generateInitialQuestion,
    fetchPreviousQuestions
  } = useQuestionGenerator();

  // Debug subscription status
  useEffect(() => {
    console.log('Current subscription status:', subscriptionStatus);
  }, [subscriptionStatus]);

  // Add debug logging
  useEffect(() => {
    console.log('Debug Subscription Values:', {
      user: !!user,
      subscriptionStatus: subscriptionStatus?.subscription_status,
      isActive: subscriptionStatus?.isActive,
    });
  }, [user, subscriptionStatus]);

  // Auto-generate a question when the component mounts for all user types
  useEffect(() => {
    // Skip if we already have a question, or if we've already attempted auto-generation
    if (generatedQuestion || hasAttemptedAutoGeneration.current) {
      console.log('Skipping auto-generation - question exists or already attempted');
      return;
    }

    // Mark that we've attempted auto-generation
    hasAttemptedAutoGeneration.current = true;
    console.log('Attempting to auto-generate a question for visitor - User status:', !!user);
    
    // Show loading state
    setInitialLoading(true);
    
    // Generate a question immediately using the special function that doesn't count against daily limit
    generateInitialQuestion()
      .catch(err => console.error('Error auto-generating initial question:', err))
      .finally(() => {
        // Hide loading state after a short delay for better UX
        setTimeout(() => {
          setInitialLoading(false);
        }, 800);
      });
  }, [generatedQuestion, generateInitialQuestion]);

  // Handle fullscreen changes
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => document.removeEventListener('fullscreenchange', handleFullscreenChange);
  }, []);

  // Toggle fullscreen
  const toggleFullscreen = async () => {
    try {
      if (!isFullscreen) {
        await questionBoxRef.current.requestFullscreen();
      } else {
        await document.exitFullscreen();
      }
    } catch (error) {
      console.error('Error toggling fullscreen:', error);
    }
  };

  // Scroll to top nav after generating
  const handleGenerate = async () => {
    console.log('Current state:', {
      isLoading,
      user: !!user,
      generationsLeft,
      selectedType,
      selectedMood,
      selectedComplexity
    });
    
    await generateQuestion();
    console.log('✅ Generation completed');
    
    // Find the header element and scroll to it
    const headerElement = document.querySelector('header');
    if (headerElement) {
      window.scrollTo({
        top: 0,
        behavior: 'auto'
      });
    }
  };

  const toggleEnergizer = (id) => {
    setExpandedEnergizers(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  useEffect(() => {
    if (user) {
      fetchPreviousQuestions();
    }
  }, [user, selectedType, fetchPreviousQuestions]);

  // Copy to clipboard function
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowCopyConfirmation(true);
      setTimeout(() => setShowCopyConfirmation(false), 2000); // Hide after 2 seconds
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <>
      <Helmet>
        <title>Check-In Questions - AI-Powered Check-In Questions Generator</title>
        <meta name="description" content="Generate engaging check-in questions, energizers, and icebreakers for your team meetings. Use AI to create personalized questions that foster connection and engagement." />
      </Helmet>
      <div className="max-w-4xl mx-auto px-1 py-1 md:py-4" role="main">
        {/* Question Display Box */}
        <div 
          className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6 mt-4 relative" 
          role="region" 
          aria-label="Generated Question Display"
        >
          {error ? (
            <div className="text-red-500 text-center" role="alert">
              <p className="text-xl font-semibold mb-2">Oops! Something went wrong</p>
              <p>{error}</p>
            </div>
          ) : isLoading ? (
            <div className="text-center" role="status" aria-live="polite">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-400 mx-auto mb-4" aria-hidden="true"></div>
              <p className="text-base text-gray-400">Generating your question...</p>
            </div>
          ) : generatedQuestion ? (
            <div 
              ref={questionBoxRef}
              className={`rounded-lg p-6 relative border border-gray-200 ${
                isFullscreen 
                  ? 'fixed inset-0 z-50 flex items-center justify-center m-0 rounded-none bg-white' 
                  : 'bg-white shadow-sm hover:shadow-md transition-shadow duration-200'
              }`}
            >
              {/* Action buttons container */}
              <div className="absolute top-4 right-4 flex items-center gap-2">
                {/* Copy button */}
                <button
                  onClick={() => copyToClipboard(
                    generatedQuestion.type === 'energizer'
                      ? `${generatedQuestion.emoji} ${generatedQuestion.title}\n\n${generatedQuestion.description}\n\n⏱️ Time: ${generatedQuestion.timeFrame}\n👥 Group Size: ${generatedQuestion.groupSize}\n🎯 Purpose: ${generatedQuestion.purpose}\n\nSteps:\n${generatedQuestion.steps.map((step, index) => `${index + 1}. ${step}`).join('\n')}\n\n${generatedQuestion.facilitatorNotes ? `Facilitator Notes:\n${generatedQuestion.facilitatorNotes.map(note => `• ${note}`).join('\n')}` : ''}`
                      : generatedQuestion.content || generatedQuestion
                  )}
                  className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-full transition-colors relative"
                  aria-label="Copy to clipboard"
                >
                  <Copy size={18} weight="bold" />
                  {/* Copy confirmation tooltip */}
                  {showCopyConfirmation && (
                    <span className="absolute bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 bg-black text-white text-xs px-2 py-1 rounded whitespace-nowrap">
                      Copied!
                    </span>
                  )}
                </button>

                {/* Fullscreen button */}
                <button
                  onClick={toggleFullscreen}
                  className="p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100 rounded-full transition-colors"
                  aria-label={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
                >
                  {isFullscreen ? (
                    <ArrowsInSimple size={18} weight="bold" />
                  ) : (
                    <ArrowsOutSimple size={18} weight="bold" />
                  )}
                </button>
              </div>

              <div className={`${isFullscreen ? 'max-w-4xl mx-auto' : ''}`}>
                <div className="mb-4" role="status" aria-label="Question Type">
                  {generatedQuestion.type === 'energizer' ? (
                    <span className="inline-block bg-green-100 text-green-800 text-xs font-medium px-3 py-0.5 rounded-full">
                      Energizer
                    </span>
                  ) : generatedQuestion.type === 'check-in' ? (
                    <span className="inline-block bg-blue-100 text-blue-800 text-xs font-medium px-3 py-0.5 rounded-full">
                      Check-In Question
                    </span>
                  ) : (
                    <span className="inline-block bg-purple-100 text-purple-800 text-xs font-medium px-3 py-0.5 rounded-full">
                      Check-Out Question
                    </span>
                  )}
                </div>

                {generatedQuestion.type === 'energizer' ? (
                  <div className="space-y-4">
                    <div className="space-y-3">
                      <h2 className="text-3xl font-bold leading-tight">
                        {generatedQuestion.emoji} {generatedQuestion.title}
                      </h2>
                      <p className="text-gray-700 leading-relaxed">{generatedQuestion.description}</p>
                    </div>
                    {!isFullscreen && (
                      <button
                        onClick={() => toggleEnergizer(generatedQuestion.id)}
                        className="flex items-center gap-2 text-blue-600 mt-3 hover:text-blue-700 font-medium"
                        aria-expanded={expandedEnergizers.has(generatedQuestion.id)}
                        aria-controls={`energizer-details-${generatedQuestion.id}`}
                      >
                        <span>
                          {expandedEnergizers.has(generatedQuestion.id) ? 'Show less' : 'Read more'}
                        </span>
                        {expandedEnergizers.has(generatedQuestion.id) ? <CaretUp size={18} aria-hidden="true" /> : <CaretDown size={18} aria-hidden="true" />}
                      </button>
                    )}
                    {(isFullscreen || expandedEnergizers.has(generatedQuestion.id)) && (
                      <div id={`energizer-details-${generatedQuestion.id}`} className="pt-4 space-y-5">
                        <div className="bg-gray-50 p-4 rounded-lg border border-gray-100">
                          <p className="text-gray-700 mb-2"><strong>⏱️ Time:</strong> {generatedQuestion.timeFrame}</p>
                          <p className="text-gray-700 mb-2"><strong>👥 Group Size:</strong> {generatedQuestion.groupSize}</p>
                          <p className="text-gray-700"><strong>🎯 Purpose:</strong> {generatedQuestion.purpose}</p>
                        </div>
                        {generatedQuestion.steps && (
                          <div>
                            <h3 className="font-bold text-lg mb-3">Steps</h3>
                            <ol className="list-decimal ml-6 space-y-3">
                              {generatedQuestion.steps.map((step, index) => (
                                <li key={index} className="leading-relaxed">{step}</li>
                              ))}
                            </ol>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="text-center">
                    <p className={`font-bold ${isFullscreen ? 'text-5xl leading-tight' : 'text-3xl leading-snug'} tracking-tight text-gray-800`} role="heading" aria-level="2">
                      &ldquo;{(generatedQuestion.content || generatedQuestion).replace(/['"]+/g, '')}&rdquo;
                    </p>
                  </div>
                )}
                {!isFullscreen && (
                  <QuestionActions
                    question={generatedQuestion}
                    onVote={fetchPreviousQuestions}
                  />
                )}
              </div>
            </div>
          ) : initialLoading ? (
            <div className="flex flex-col items-center justify-center py-12">
              <div className="animate-pulse flex flex-col items-center">
                <div className="h-4 bg-gray-200 rounded w-32 mb-4"></div>
                <div className="h-8 bg-gray-200 rounded w-3/4 mb-2"></div>
                <div className="h-8 bg-gray-200 rounded w-2/3 mb-2"></div>
                <div className="h-8 bg-gray-200 rounded w-1/2 mb-8"></div>
                <div className="flex space-x-4">
                  <div className="h-10 bg-gray-200 rounded w-24"></div>
                  <div className="h-10 bg-gray-200 rounded w-24"></div>
                  <div className="h-10 bg-gray-200 rounded w-24"></div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center py-12">
              <p className="text-xl text-gray-600 mb-4">
                {isLoading ? 'Generating your question...' : 'Click Generate to create a new question'}
              </p>
            </div>
          )}
        </div>

        {/* Controls Box */}
        <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mb-6" role="region" aria-label="Question Generator Controls">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
            {/* Type Column */}
            <div role="radiogroup" aria-label="Question Type">
              <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Type</p>
              <div className="space-y-1 md:space-y-2">
                <ControlOption
                  label="Check-In"
                  emoji="👋"
                  isSelected={selectedType === 'check-in'}
                  onChange={() => handleTypeChange('check-in')}
                  ariaLabel="Select Check-In question type"
                />
                <ControlOption
                  label="Energizer"
                  emoji="⚡️"
                  isSelected={selectedType === 'energizer'}
                  onChange={() => handleTypeChange('energizer')}
                  ariaLabel="Select Energizer activity type"
                />
                <ControlOption
                  label="Check-Out"
                  emoji="🏁"
                  isSelected={selectedType === 'check-out'}
                  onChange={() => handleTypeChange('check-out')}
                  ariaLabel="Select Check-Out question type"
                />
              </div>
            </div>

            {/* Mood Column */}
            <div role="radiogroup" aria-label="Question Mood">
              <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Mood</p>
              <div className="space-y-1 md:space-y-2">
                <ControlOption
                  label="Curious"
                  emoji="🤔"
                  isSelected={selectedMood === 'curious'}
                  onChange={() => handleMoodChange('curious')}
                  ariaLabel="Select Curious mood"
                />
                <ControlOption
                  label="Personal"
                  emoji="👤"
                  isSelected={selectedMood === 'personal'}
                  onChange={() => handleMoodChange('personal')}
                  ariaLabel="Select Personal mood"
                />
                <ControlOption
                  label="Weird"
                  emoji="🌀"
                  isSelected={selectedMood === 'weird'}
                  onChange={() => handleMoodChange('weird')}
                  ariaLabel="Select Weird mood"
                />
              </div>
            </div>

            {/* Complexity Column */}
            <div role="radiogroup" aria-label="Question Complexity">
              <p className="text-lg md:text-xl font-bold mb-4 md:mb-6">Complexity</p>
              <div className="space-y-1 md:space-y-2">
                <ControlOption
                  label="Simple"
                  emoji="✅"
                  isSelected={selectedComplexity === 'simple'}
                  onChange={() => handleComplexityChange('simple')}
                  ariaLabel="Select Simple complexity"
                />
                <ControlOption
                  label="Moderate"
                  emoji="🔸"
                  isSelected={selectedComplexity === 'moderate'}
                  onChange={() => handleComplexityChange('moderate')}
                  ariaLabel="Select Moderate complexity"
                />
                <ControlOption
                  label="Deep"
                  emoji="🧠"
                  isSelected={selectedComplexity === 'deep'}
                  onChange={() => handleComplexityChange('deep')}
                  ariaLabel="Select Deep complexity"
                />
              </div>
            </div>
          </div>

          <div className="mt-8">
            <button
              onClick={handleGenerate}
              className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-4 px-8 rounded-xl text-xl transition duration-200 ease-in-out transform hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100 disabled:bg-gray-400"
              disabled={isLoading || (!user && generationsLeft === 0) || (user && subscriptionStatus?.subscription_status !== 'premium' && generationsLeft === 0)}
              aria-busy={isLoading}
              aria-label={isLoading ? 'Generating question...' : 'Generate new question'}
            >
              {isLoading 
                ? 'Generating...' 
                : (!user && generationsLeft === 0) || (user && subscriptionStatus?.subscription_status !== 'premium' && generationsLeft === 0)
                ? 'Free limit reached'
                : 'Generate'}
            </button>

            {/* Show remaining generations for non-premium users */}
            {(!user || (user && subscriptionStatus?.subscription_status !== 'premium')) && (
              <div className="text-center mt-4" role="status" aria-live="polite">
                <p className="text-gray-600 mb-2">
                  {generationsLeft > 0 
                    ? `${generationsLeft} free generations remaining`
                    : "You've reached the limit for free generations"}
                </p>
                {!user ? (
                  <a 
                    href="/sign-up" 
                    className="text-blue-600 hover:text-blue-700 font-medium inline-flex items-center"
                    role="button"
                  >
                    Create account <span className="ml-1" aria-hidden="true">→</span>
                  </a>
                ) : (
                  <a 
                    href="/pricing" 
                    className="text-blue-600 hover:text-blue-700 font-medium inline-flex items-center"
                    role="button"
                  >
                    Upgrade to Premium <span className="ml-1" aria-hidden="true">→</span>
                  </a>
                )}
              </div>
            )}
          </div>
        </div>

        {/* Premium Upgrade Box - Only show for non-premium users */}
        {user && subscriptionStatus?.subscription_status !== 'premium' && (
          <div className="mt-6 mb-6 bg-gradient-to-r from-blue-600 to-blue-700 rounded-3xl text-white p-8">
            <div className="flex flex-col md:flex-row items-start gap-8">
              <div className="flex-1 md:w-3/5 space-y-6">
                <div>
                  <h2 className="text-2xl font-bold mb-2">Check-in Questions Premium</h2>
                  <p className="opacity-90">Your all-in-one solution for creating engaging team moments and fostering psychological safety.</p>
                </div>

                <ul className="space-y-3">
                  <li className="flex items-center">
                    <span className="mr-3">✨</span>
                    Unlimited access to our AI-powered question generator
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3">🎯</span>
                    Advanced energizer activities and team exercises
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3">📈</span>
                    Access to top-rated questions from our global community
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3">🌐</span>
                    Monthly online workshops on facilitation best practices
                  </li>
                  <li className="flex items-center">
                    <span className="mr-3">🌐</span>
                    Join our global community of facilitators and team leaders
                  </li>
                </ul>
              </div>

              <div className="w-full md:w-2/5 flex flex-col items-center md:items-end gap-6">
                <div className="text-center md:text-right">
                  <p className="text-lg text-white/50 mb-1">Starting at</p>
                  <p className="text-4xl font-bold">${isAnnual ? '50' : '5'}/{isAnnual ? 'year' : 'month'}</p>
                </div>

                {/* Pricing Toggle */}
                <div className="flex items-center justify-center md:justify-end space-x-4 w-full">
                  <span className={`text-sm ${!isAnnual ? 'text-white font-medium' : 'text-white/75'}`}>
                    Monthly
                  </span>
                  <button
                    onClick={() => setIsAnnual(!isAnnual)}
                    className={`relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
                      isAnnual ? 'bg-white' : 'bg-white/50'
                    }`}
                  >
                    <span
                      className={`inline-block h-4 w-4 transform rounded-full transition-transform ${
                        isAnnual ? 'translate-x-6 bg-blue-600' : 'translate-x-1 bg-white'
                      }`}
                    />
                  </button>
                  <span className={`text-sm ${isAnnual ? 'text-white font-medium' : 'text-white/75'}`}>
                    Annually (Save 17%)
                  </span>
                </div>

                <button
                  onClick={async () => {
                    try {
                      const mode = process.env.REACT_APP_STRIPE_MODE || 'live';
                      const prices = SUBSCRIPTION_PRICES[mode];
                      trackSubscriptionAttempt(isAnnual ? 'yearly' : 'monthly');
                      await createCheckoutSession(isAnnual ? prices.yearly : prices.monthly, user.email);
                    } catch (error) {
                      console.error('Subscription error:', error);
                    }
                  }}
                  className="inline-block bg-white text-blue-600 font-bold py-3 px-8 rounded-xl text-lg transition duration-200 ease-in-out transform hover:scale-[1.02] hover:bg-gray-50 w-full md:w-auto text-center"
                >
                  Upgrade to Premium
                </button>
                <div className="flex items-center justify-center md:justify-end space-x-1 text-sm text-white/50 w-full text-center">
                  <Lock size={14} weight="fill" className="relative -top-px" />
                  <span>Payments processed securely with Stripe</span>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Popular Questions - Only show for premium users */}
        {user && subscriptionStatus?.subscription_status === 'premium' && previousQuestions.length > 0 && (
          <div className="bg-white rounded-3xl shadow-lg p-8 md:p-12 mt-6">
            <h2 className="text-2xl font-bold mb-6">
              Most Popular {selectedType === 'check-in' 
                ? 'Check-In Questions' 
                : selectedType === 'energizer' 
                  ? 'Team Energizers' 
                  : 'Check-Out Questions'}
            </h2>
            <div className="space-y-6">
              {previousQuestions
                .filter(question => {
                  const votes = parseInt(question.voteCount) || 0;
                  return votes >= 1;
                })
                .slice(0, 20)
                .map((question) => (
                  <PopularQuestionItem
                    key={question.id}
                    question={question}
                    toggleEnergizer={toggleEnergizer}
                    expandedEnergizers={expandedEnergizers}
                    onVote={fetchPreviousQuestions}
                    getQuestionTypePill={getQuestionTypePill}
                  />
                ))}
            </div>
          </div>
        )}

        {!user && (
          <div className="mt-6 mb-6 bg-white rounded-3xl shadow-lg p-8 md:p-12">
            <h1 className="text-3xl font-bold mb-6">Welcome to checkinquestions.com</h1>
            
            <p className="mb-8 text-gray-700">
              Checkinquestions.com is an AI-powered platform designed to help team leaders, facilitators, and managers create engaging check-in questions and team energizers that foster psychological safety, improve communication, and strengthen team dynamics. Our platform harnesses the power of artificial intelligence and OpenAI's technology, combined with proven team building and facilitation techniques, to generate thoughtful, engaging check-in questions and team icebreakers. Whether you're running daily stand-ups, sprint retrospectives, team building workshops, or leadership meetings, our smart question generator helps meeting facilitators and team leaders spark authentic conversations that strengthen team dynamics and improve employee engagement.</p>

            <div className="grid md:grid-cols-2 gap-8 mb-8">
              <div>
                <h2 className="text-xl font-semibold mb-4">✨ Why Teams Choose Us</h2>
                <ul className="space-y-3 text-gray-700">
                  <li className="flex items-start">
                    <span className="mr-3">🤝</span>
                    Build psychological safety and trust
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">🎯</span>
                    Increase meeting engagement
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">🌈</span>
                    Create inclusive team environments
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">🚀</span>
                    Accelerate team development
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">🌐</span>
                    Perfect for remote & hybrid teams
                  </li>
                </ul>
              </div>

              <div>
                <h2 className="text-xl font-semibold mb-4">💫 Perfect For</h2>
                <ul className="space-y-3 text-gray-700">
                  <li className="flex items-start">
                    <span className="mr-3">👥</span>
                    Team Leaders & Managers
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">⚡️</span>
                    Agile Coaches & Scrum Masters
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">📚</span>
                    Educators & Facilitators
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">💡</span>
                    Innovation Teams
                  </li>
                  <li className="flex items-start">
                    <span className="mr-3">🤗</span>
                    Community Leaders
                  </li>
                </ul>
              </div>
            </div>

            <p className="mb-8 text-gray-700">
              As remote work and distributed teams become increasingly common, building trust and maintaining team cohesion has never been more crucial. Our AI-powered meeting tools help Scrum Masters, Agile Coaches, HR professionals, and team managers create inclusive environments where every team member feels heard and valued. By incorporating expert-designed check-in questions and team energizers into your meeting rituals, you'll enhance team collaboration, boost meeting participation, and develop the psychological safety that's essential for high-performing teams.
            </p>

            <h2 className="text-2xl font-semibold mb-4">🎯 A Comprehensive Platform</h2>
            <p className="text-gray-700 mb-6">
              Our platform offers three powerful interaction types: Check-In Questions to start meetings meaningfully, ⚡️ Energizers to boost engagement, and 🏁 Check-Out Questions to close sessions with purpose. Each question adapts to your preferred mood and complexity, ensuring perfect alignment with your team's needs.
            </p>

            <div className="bg-gradient-to-r from-blue-600 to-blue-700 rounded-3xl text-white p-8 mb-6">
              <h3 className="text-xl font-semibold mb-4">🌟 Premium Features</h3>
              <div className="grid md:grid-cols-2 gap-6 mb-2">
                <div>
                  <ul className="space-y-3 text-white">
                    <li className="flex items-start">
                      <span className="mr-3">✨</span>
                      Unlimited AI-powered questions
                    </li>
                    <li className="flex items-start">
                      <span className="mr-3">🎯</span>
                      Advanced team energizers
                    </li>
                    <li className="flex items-start">
                      <span className="mr-3">📈</span>
                      Access curated question library
                    </li>
                  </ul>
                </div>
                <div>
                  <ul className="space-y-3 text-white">
                    <li className="flex items-start">
                      <span className="mr-3">🎓</span>
                      Monthly facilitation workshops
                    </li>
                    <li className="flex items-start">
                      <span className="mr-3">👥</span>
                      Global facilitator community
                    </li>
                    <li className="flex items-start">
                      <span className="mr-3">💫</span>
                      Priority feature access
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <p className="text-gray-700 mb-6">
              Start your journey today with 25 free questions daily. Experience how our AI-powered platform can transform your team meetings into engaging, meaningful conversations that build stronger connections and drive better outcomes. Join thousands of facilitators worldwide who are already using Check-In Questions to create more impactful team moments.
            </p>

            <div className="flex justify-center">
              <a
                href="/sign-up"
                className="inline-block bg-green-500 text-white font-medium py-3 px-8 rounded-xl text-lg text-center transition duration-200 ease-in-out transform hover:scale-[1.04] hover:bg-green-600"
              >
                Start Your Journey with a Free Account Today ✨
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
}